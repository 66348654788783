/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:31:07
 * @modify date 2019-01-28 01:31:07
 * @desc [description]
 */
export const MESSAGES_OWN_ADD = 'MESSAGES_OWN_ADD';
export type MESSAGES_OWN_ADD = typeof MESSAGES_OWN_ADD;

export const MESSAGES_OWN_UPDATE_RECEIVED = 'MESSAGES_OWN_UPDATE_RECEIVED';
export type MESSAGES_OWN_UPDATE_RECEIVED = typeof MESSAGES_OWN_UPDATE_RECEIVED;

export const MESSAGES_OWN_UPDATE_SEEN = 'MESSAGES_OWN_UPDATE_SEEN';
export type MESSAGES_OWN_UPDATE_SEEN = typeof MESSAGES_OWN_UPDATE_SEEN;

export const MESSAGES_OWN_UPDATE_SEND_STATUS = 'MESSAGES_OWN_UPDATE_SEND_STATUS';
export type MESSAGES_OWN_UPDATE_SEND_STATUS = typeof MESSAGES_OWN_UPDATE_SEND_STATUS;

export const MESSAGES_BOT_ADD = 'MESSAGES_BOT_ADD';
export type MESSAGES_BOT_ADD = typeof MESSAGES_BOT_ADD;

export const MESSAGES_BOT_RESET = 'MESSAGES_BOT_RESET';
export type MESSAGES_BOT_RESET = typeof MESSAGES_BOT_RESET;

export const MESSAGES_TYPING_SET_ON = 'MESSAGES_TYPING_SET_ON';
export type MESSAGES_TYPING_SET_ON = typeof MESSAGES_TYPING_SET_ON;

export const MESSAGES_TYPING_SET_OFF = 'MESSAGES_TYPING_SET_OFF';
export type MESSAGES_TYPING_SET_OFF = typeof MESSAGES_TYPING_SET_OFF;