/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:40:45
 * @modify date 2019-01-28 01:40:45
 * @desc [description]
 */
export interface IScreenResolution {
	screenWidth: number;
	screenHeight: number;
}

export const getScreenResolution = (): IScreenResolution => ({
	screenHeight: window.screen.height,
	screenWidth: window.screen.width
});

export const deviceShouldFullscreen = (screenResolution: IScreenResolution): boolean =>
	screenResolution.screenWidth <= 480 || screenResolution.screenHeight <= 480;