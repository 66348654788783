import * as React from 'react';
import Html5QrcodePlugin from './Html5QrcodePlugin';
import ResultContainerPlugin from './ResultContainerPlugin';
import { sendTextMessage } from '../../../../socket-handler/socket-handler';
// import HowToUse from './HowToUse.jsx';

export interface IScannerModuleProps {
	userID: string;
}
export interface IScannerModuleState {
	decodedResults: Array<{ decodedText: String, results: any }>;
	ScannerActive: boolean;
	resultPresent: boolean;
}
export class ScannerModule extends React.Component<IScannerModuleProps, IScannerModuleState> {
	constructor(props: IScannerModuleProps) {
		super(props);
		this.state = {
			decodedResults: [],
			ScannerActive: true,
			resultPresent: false
		};

		// This binding is necessary to make `this` work in the callback.
		this.onNewScanResult = this.onNewScanResult.bind(this);
		this.setScannerStatus = this.setScannerStatus.bind(this);
	}

	render() {
		return (
			<div>
				{this.state.resultPresent && <ResultContainerPlugin results={this.state.decodedResults} />}
				{/* <HowToUse /> */}
				{this.state.resultPresent && <button onClick={this.setScannerStatus}>Diesen Barcode verwenden</button>}
				{!this.state.resultPresent && <Html5QrcodePlugin
					fps={10}
					qrbox={250}
					disableFlip={false}
					qrCodeSuccessCallback={this.onNewScanResult}
				/>}
			</div>
		);
	}
	setScannerStatus() {
		var lastresult = this.state.decodedResults[this.state.decodedResults.length - 1];
		if (lastresult !== undefined) {
			console.log(lastresult.decodedText);
			sendTextMessage(lastresult.decodedText.toString(), this.props.userID, true);
			this.setState({ ScannerActive: false });
		}
	}
	onNewScanResult(decodedText: any, decodedResult: any) {
		console.log(
			'App [result]', decodedResult);

		var decodedResults = this.state.decodedResults;
		decodedResults.push(decodedResult);
		this.setState((state, props) => {
			state.decodedResults.push(decodedResult);
			return state;
		}
		);
		this.setState({ resultPresent: true });
	}
}