/**
 * @author Moataz Ibrahim
 * @email moataz90@gmail.com
 * @create date 2019-01-28 01:20:05
 * @modify date 2019-01-28 01:20:05
 * @desc [description]
 */
import * as React from 'react';
import { IPropsChildable } from '../../types/common-proptypes';
import { Message } from '../messages/Message';
import styled, { keyframes } from 'styled-components';

export interface IQuickReplyListProps extends IPropsChildable {
	children: React.ReactNode[];
}

const FadeIn = keyframes`
0%   {opacity:0; transform:  translate(0px,15px); }
100% {opacity:1; transform:  translate(0px,0px);}

0%   {opacity:0; -moz-transform:  translate(0px,15px);}
100% {opacity:1; -moz-transform:  translate(0px,0px);}
0%   {opacity:0; -webkit-transform:  translate(0px,15px);}
100% {opacity:1; -webkit-transform:  translate(0px,0px);}
0%   {opacity:0; -o-transform:  translate(0px,15px)}
100% {opacity:1; -o-transform:  translate(0px,0px);}
0%   {opacity:0; -ms-transform:  translate(0px,15px);}
100% {opacity:1; -ms-transform:  translate(0px,0px);}
`;

const QuickReplyListAnimation = styled.div`
width: 100%;
position: relative;
box-sizing: border-box;
display: flex;
align-items: flex-end;
-webkit-box-pack: end;
justify-content: flex-end;
padding-right: 10px;
padding-top: 5px;
flex-flow: row wrap;
/*
direction: ltr;
animation: ${FadeIn} ease 1s;
animation-iteration-count: 1;
transform-origin: 10% 10%;
animation-fill-mode:backwards; /*when the spec is finished*/
-webkit-animation: ${FadeIn} ease 1s;
-webkit-animation-iteration-count: 1;
-webkit-transform-origin: 10% 10%;
-webkit-animation-fill-mode:backwards; /*Chrome 16+, Safari 4+*/
-moz-animation: ${FadeIn} ease 1s;
-moz-animation-iteration-count: 1;
-moz-transform-origin: 10% 10%;
-moz-animation-fill-mode:backwards; /*FF 5+*/
-o-animation: ${FadeIn} ease 1s;
-o-animation-iteration-count: 1;
-o-transform-origin: 10% 10%;
-o-animation-fill-mode:backwards; /*Not implemented yet*/
-ms-animation: ${FadeIn} ease 1s;
-ms-animation-iteration-count: 1;
-ms-transform-origin: 10% 10%;
-ms-animation-fill-mode:backwards; /*IE 10+*/

opacity:0;
opacity: 1\9;*/
`;

const QuickReplyListComponent: React.StatelessComponent<IQuickReplyListProps> = (props) => {

	return (
		<Message isOwnMessage={true} isLastMessage={false}>
			<QuickReplyListAnimation >{props.children}</QuickReplyListAnimation>
		</Message>
	);
};

export const QuickReplyList = QuickReplyListComponent;