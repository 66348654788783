/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:44:48
 * @modify date 2019-01-28 01:44:48
 * @desc [description]
 */
export enum MessageMediaType {
	Audio,
	Video,
	Image,
	File
}