/**
 * @author Moataz Ibrahim
 * @email moataz90@gmail.com
 * @create date 2019-01-28 01:20:05
 * @modify date 2019-01-28 01:20:05
 * @desc [description]
 */
export const CATEGORY_LIST_SET = 'CATEGORY_LIST_SET';
export type CATEGORY_LIST_SET = typeof CATEGORY_LIST_SET;

export const TAGS_SUGGESTION_LIST_SET = 'TAGS_SUGGESTION_LIST_SET ';
export type TAGS_SUGGESTION_LIST_SET = typeof TAGS_SUGGESTION_LIST_SET;