/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:33:32
 * @modify date 2019-01-28 01:33:32
 * @desc [description]
 */
export const UI_PLUGIN_ENABLED_SET = 'UI_PLUGIN_ENABLED_SET';
export type UI_PLUGIN_ENABLED_SET = typeof UI_PLUGIN_ENABLED_SET;

export const UI_WINDOW_MODE_SET = 'UI_WINDOW_MODE_SET';
export type UI_WINDOW_MODE_SET = typeof UI_WINDOW_MODE_SET;

export const UI_OVERLAY_VISIBILITY_SET = 'UI_OVERLAY_VISIBILITY_SET';
export type UI_OVERLAY_VISIBILITY_SET = typeof UI_OVERLAY_VISIBILITY_SET;

export const UI_DEVICE_SET = 'UI_DEVICE_SET';
export type UI_DEVICE_SET = typeof UI_DEVICE_SET;

export const UI_CHAT_SHOULD_SCROLL_DOWN = 'UI_CHAT_SHOULD_SCROLL_DOWN';
export type UI_CHAT_SHOULD_SCROLL_DOWN = typeof UI_CHAT_SHOULD_SCROLL_DOWN;