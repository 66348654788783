import { Html5QrcodeScanner } from 'html5-qrcode';
import * as React from 'react';

const qrcodeRegionId = 'html5qr-code-full-region';

export interface IQrCodeProps {
	fps: any;
	qrbox: any;
	disableFlip: any;
	qrCodeSuccessCallback: any;
	aspectRatio?: any;
	verbose?: boolean;
	qrCodeErrorCallback?: any;
	rememberLastUsedCamera?: any;
}

export interface IQrCodeState {
	html5QrcodeScanner: any;
}

class Html5QrcodePlugin extends React.Component<IQrCodeProps, IQrCodeState> {
	constructor(props: IQrCodeProps) {
		super(props);
		this.state = { html5QrcodeScanner: null };
	}
	public componentWillUnmount() {
		try {
			this.state.html5QrcodeScanner.clear();
		} catch (error) {
			console.error('Failed to clear html5QrcodeScanner. ', error);
		}
	}

	componentDidMount() {
		// Creates the configuration object for Html5QrcodeScanner.
		function createConfig(props: IQrCodeProps) {
			var config: IQrCodeProps = {
				fps: null,
				qrbox: null,
				disableFlip: null,
				verbose: null,
				qrCodeSuccessCallback: null
			};
			if (props.fps) {
				config.fps = props.fps;
			}
			if (props.qrbox) {
				config.qrbox = props.qrbox;
			}
			if (props.aspectRatio) {
				config.aspectRatio = props.aspectRatio;
			}
			if (props.disableFlip !== undefined) {
				config.disableFlip = props.disableFlip;
			}
			if (props.rememberLastUsedCamera !== undefined) {
				config.rememberLastUsedCamera = props.rememberLastUsedCamera;
			}
			return config;
		}

		var newconfig = createConfig(this.props);
		var verbose = this.props.verbose === true;

		// Suceess callback is required.
		if (!(this.props.qrCodeSuccessCallback)) {
			throw 'qrCodeSuccessCallback is required callback.';
		}

		this.state = {
			html5QrcodeScanner: new Html5QrcodeScanner(
				qrcodeRegionId, newconfig, verbose)
		};
		this.state.html5QrcodeScanner.render(
			this.props.qrCodeSuccessCallback,
			this.props.qrCodeErrorCallback);
	}
	render() {
		return (
			<div
				id={qrcodeRegionId}
			/>
		);
	}

}

export default Html5QrcodePlugin;