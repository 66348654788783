/**
 * @author Moataz Ibrahim
 * @email moataz90@gmail.com
 * @create date 2019-01-28 01:20:05
 * @modify date 2019-01-28 01:20:05
 * @desc [description]
 */
import * as constants from './idea.constants';
import { ICategory, ISubCategory } from './idea.schema';
import { Timestamp, ISuggestedTags } from '../../types/common-types';

export interface ICategoryListStateSet {
	type: constants.CATEGORY_LIST_SET;
	payload: ISubCategory[];
}

export const categoryListSet = (categoryList: ISubCategory[]): ICategoryListStateSet => ({
	type: constants.CATEGORY_LIST_SET,
	payload: categoryList
});

export interface ITagsSuggestionListStateSet {
	type: constants.TAGS_SUGGESTION_LIST_SET;
	payload: ISuggestedTags;
}

export const tagsSuggestionListSet = (tagsList: ISuggestedTags): ITagsSuggestionListStateSet => ({
	type: constants.TAGS_SUGGESTION_LIST_SET,
	payload: tagsList
});

export type IdeaSummaryAction = ICategoryListStateSet | ITagsSuggestionListStateSet;
