/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:40:42
 * @modify date 2019-01-28 01:40:42
 * @desc [description]
 */
import { waitUntil } from './../other/wait-until';
import { Device } from './../../redux-store/ui/ui.schema';

enum WurflJSFormFactor {
	Smartphone = 'Smartphone',
	FeaturePhone = 'Feature Phone',
	OtherMobile = 'Other Mobile',
	Tablet = 'Tablet',
	Desktop = 'Desktop',
	OtherNonMobile = 'Other non-Mobile'
}

export const detectDevice = (): Device => {
	// Wait until global WURFL.js object is available

	var isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf('Mobi') > -1;
	console.log(isMobile);

	if (isMobile) {
		console.log(isMobile);
		return Device.Smartphone;
	} else {
		return Device.Desktop;
	}

	// return waitUntil(() => typeof WURFL !== 'undefined', 4000)
	// 	.then(() => {
	// 		if ([
	// 			WurflJSFormFactor.Smartphone,
	// 			WurflJSFormFactor.FeaturePhone,
	// 			WurflJSFormFactor.OtherMobile
	// 		].indexOf(WURFL.form_factor) > -1) {
	// 			return Device.Smartphone;
	// 		} else if ([WurflJSFormFactor.Tablet].indexOf(WURFL.form_factor) > -1) {
	// 			return Device.Tablet;
	// 		} else if ([WurflJSFormFactor.Desktop, WurflJSFormFactor.OtherNonMobile].indexOf(WURFL.form_factor) > -1) {
	// 			return Device.Desktop;
	// 		} else {
	// 			return Device.Other;
	// 		}
	// 	})
	// 	.catch((err) => {
	// 		console.error(err);
	// 		console.warn('WURFL.js object could not be found');

	// 		return Device.Other;
	// 	});
};

// v