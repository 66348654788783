/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:41:17
 * @modify date 2019-01-28 01:41:17
 * @desc [description]
 */
export const isDevEnv = (): boolean => process.env.NODE_ENV === 'development';

export const isProdEnv = (): boolean => process.env.NODE_ENV === 'production';

export const isTestEnv = (): boolean => process.env.NODE_ENV === 'testing';