/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:32:48
 * @modify date 2019-01-28 01:32:48
 * @desc [description]
 */

export enum TextboxMode {
	Text = 'text',
	Tags = 'tags'
}
export interface ITextbox {
	show: boolean;
	mode: TextboxMode;
}

export interface IToolbarState {
	textbox: ITextbox;
}
