/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:32:23
 * @modify date 2019-01-28 01:32:23
 * @desc [description]
 */
export enum SocketState {
	Disconnected = 'disconnected',
	Connecting = 'connecting',
	Reconnecting = 'reconnecting',
	WaitingForReconnect = 'waiting_for_reconnect',
	Connected = 'connected',
	Failed = 'failed'
}

export interface ISocketState {
	state: SocketState;
	nextReconnect: number;
	alreadyBeenConnected: boolean;
}