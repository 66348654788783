/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:37:38
 * @modify date 2019-01-28 01:37:38
 * @desc [description]
 */
/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:37:35
 * @modify date 2019-01-28 01:37:35
 * @desc [description]
 */
import styled from 'styled-components';

const ChatViewHeaderIconStyled = styled.img`
	height: 40px;
	width: 40px;

`;

export const ChatViewHeaderIcon = ChatViewHeaderIconStyled;