/**
 * @author Moataz Ibrahim
 * @email moataz90@gmail.com
 * @create date 2019-01-28 01:20:05
 * @modify date 2019-01-28 01:20:05
 * @desc [description]
 */
import { stringify } from 'querystring';
import {
	IMessage, MessageType, IMessagePayloadText, IMessagePayloadMedia, IMessagePayloadCarousel,
	IMessagePayloadIdea, IMessagePayloadMap, IMessagePayloadTags, IMessagePayloadScanner
}
	from './../../redux-store/messages/messages.schema';

export const determineMessageType = (message: IMessage<any>): MessageType => {
	if ((message as IMessage<IMessagePayloadTags>).payload.tags) {
		return MessageType.Tags;
	} else if ((message as IMessage<IMessagePayloadMap>).payload.location) {
		return MessageType.Map;
	} else if ((message as IMessage<IMessagePayloadIdea>).payload.idea) {
		return MessageType.Idea;
	} else if ((message as IMessage<IMessagePayloadScanner>).payload.scanner) {
		return MessageType.Scanner;
	} else if ((message as IMessage<IMessagePayloadText>).payload.text) {
		return MessageType.Text;
	} else if ((message as IMessage<IMessagePayloadMedia>).payload.url) {
		return MessageType.Media;
	} else if ((message as IMessage<IMessagePayloadCarousel>).payload.cards) {
		return MessageType.Carousel;
	} else {
		return null;
	}
};

export const isLastMessageTypeTags = (messages: IMessage<any>[]): boolean => {
	if (messages.length > 0) {
		const lastMessage = messages[messages.length - 1];
		if (lastMessage.payload.tags) {
			return true;
		}
	}
	return false;
};