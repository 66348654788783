/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:32:12
 * @modify date 2019-01-28 01:32:12
 * @desc [description]
 */
export const SOCKET_STATE_SET = 'SOCKET_STATE_SET';
export type SOCKET_STATE_SET = typeof SOCKET_STATE_SET;

export const SOCKET_NEXT_RECONNECT_TIME_SET = 'SOCKET_NEXT_RECONNECT_TIME_SET';
export type SOCKET_NEXT_RECONNECT_TIME_SET = typeof SOCKET_NEXT_RECONNECT_TIME_SET;

export const SOCKET_HAS_ALREADY_BEEN_CONNECTED_SET = 'SOCKET_HAS_ALREADY_BEEN_CONNECTED_SET';
export type SOCKET_HAS_ALREADY_BEEN_CONNECTED_SET = typeof SOCKET_HAS_ALREADY_BEEN_CONNECTED_SET;