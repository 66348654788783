/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:42:11
 * @modify date 2019-01-28 01:42:11
 * @desc [description]
 */
import * as React from 'react';
/* tslint:disable:no-unused-variable */
import styled, { StyledComponentClass, keyframes } from 'styled-components';
import { IPropsStyledComponents } from '../../types/common-proptypes';
import { ButtonCallback } from '../../types/button-types';

export interface IButtonProps extends IPropsStyledComponents {
	title: string;
	payload: any;
	type: number;
	onClick: ButtonCallback;
	backgroundColor: string;
	fontColor: string;
	fontSize: number;
	fontFamily: string;
	idx: number;
	firstButtonMarginTop: number;
	delay?: number;
}

const ButtonComponent: React.StatelessComponent<IButtonProps> = (props) => {
	const { className, onClick } = props;
	return (

		<div
			className={className}
			onClick={onClick.bind(undefined, props.type, props.title, props.payload)}
		>
			{props.title}
		</div>
	);
};

const FadeIn = keyframes`
0%   {opacity:0; transform:  translate(0px,15px); }
100% {opacity:1; transform:  translate(0px,0px);}

0%   {opacity:0; -moz-transform:  translate(0px,15px);}
100% {opacity:1; -moz-transform:  translate(0px,0px);}
0%   {opacity:0; -webkit-transform:  translate(0px,15px);}
100% {opacity:1; -webkit-transform:  translate(0px,0px);}
0%   {opacity:0; -o-transform:  translate(0px,15px)}
100% {opacity:1; -o-transform:  translate(0px,0px);}
0%   {opacity:0; -ms-transform:  translate(0px,15px);}
100% {opacity:1; -ms-transform:  translate(0px,0px);}
`;

const ButtonStyled = styled(ButtonComponent)`
	&&& {
		width: fit-content;
		padding: 4px 6px 4px 6px;
		margin-left: 6px;
		background-color: ${props => props.backgroundColor};
		font-size: ${props => props.fontSize}px;
		color: ${props => props.fontColor};
		border-radius: 20px;
		margin-top: ${props => props.idx === 0 ? props.firstButtonMarginTop : 6}px;
		font-family: ${props => props.fontFamily};
		align-self: flex-end;
		cursor: pointer;
		z-index: 10;
		
		position: relative;




	}

	&&&:hover{
		opacity: 0.8;
	}
`;

export const Button = ButtonStyled;