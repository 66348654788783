import { any } from 'prop-types';
import * as React from 'react';

function filterResults(results: any) {
	let filteredResults = [];
	for (var i = 0; i < results.length; ++i) {
		if (i === 0) {
			filteredResults.push(results[i]);
			continue;
		}

		if (results[i].decodedText !== results[i - 1].decodedText) {
			filteredResults.push(results[i]);
		}
	}
	return filteredResults;
}

export interface IResultsTableProps {
	data: any;
}

class ResultContainerTable extends React.Component<IResultsTableProps> {
	constructor(props: any) {
		super(props);
	}
	render() {
		var results = filterResults(this.props.data);
		var barcode = 'BITTE SCANNEN';
		if (results.length !== 0) {
			barcode = results[results.length - 1].decodedText;
		}
		return (
			<div>
				{barcode}
			</div>
		);
	}
}

export interface IResultsContainerProps {
	results: any;
}

class ResultContainerPlugin extends React.Component<IResultsContainerProps> {
	constructor(props: any) {
		super(props);
	}
	render() {
		let results = filterResults(this.props.results);
		console.log('RESULT:' + results);
		return (<div className="Result-container">
			<div className="Result-header">Erkannter Barcode:</div>
			<div className="Result-section">
				<ResultContainerTable data={this.props.results} />
			</div>
		</div>);
	}
}

export default ResultContainerPlugin;