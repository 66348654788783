/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:32:39
 * @modify date 2019-01-28 01:32:39
 * @desc [description]
 */

export const TEXTBOX_MODE_TEXT = 'TEXTBOX_MODE_TEXT ';
export type TEXTBOX_MODE_TEXT = typeof TEXTBOX_MODE_TEXT;

export const TEXTBOX_MODE_TAGS = 'TEXTBOX_MODE_TAGS ';
export type TEXTBOX_MODE_TAGS = typeof TEXTBOX_MODE_TAGS;