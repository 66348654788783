/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:41:27
 * @modify date 2019-01-28 01:41:27
 * @desc [description]
 */
export const urlHasReferralPayload = (url: string): boolean =>
	url.indexOf('pwp_ref=') > -1;

export const getReferralPayload = (queryParams: Map<string, any>) => {
	return queryParams.get('pwp_ref');
};