/**
 * @author Moataz Ibrahim
 * @email moataz90@gmail.com
 * @create date 2019-01-28 01:20:05
 * @modify date 2019-01-28 01:20:05
 * @desc [description]
 */
import * as React from 'react';
/* tslint:disable:no-unused-variable */
import styled, { StyledComponentClass, keyframes } from 'styled-components';
import { IPropsStyledComponents } from '../../../../types/common-proptypes';
import { Message, IMessageBaseProps } from '../../Message';
import { IButtonProps, Button } from '../../../buttons/Button';
import { IIdea, IExtQuestion } from './../../../../../redux-store/messages/messages.schema';
import { IdeaSummaryFactory } from './IdeaSummaryFactory';
import { IIdeaSummaryState } from '../../../../../redux-store/idea-summary/idea.schema';
import { sendPostbackMessage } from '../../../../../socket-handler/socket-handler';
import * as moment from 'moment';

export enum Section {
	start,
	middle,
	end,
	single
}
export interface IIdeaProps extends IMessageBaseProps, IPropsStyledComponents {
	userID: string;
	idea: IIdea;
	data: IIdeaSummaryState;
	fontColor: string;
	backgroundColor: string;
	maxWidth: number | string;
	fontFamily: string;
	isOwnMessage: boolean;
	buttons?: React.ReactElement<IButtonProps>[];
	section?: Section;
	readOnlyFontColor: string;
}

export interface IIdeaState {
	idea: IIdea;
	currentTag: string;
	updateSent: string;
	tags: ITag[];
	suggestions: ITag[];
}

const UpdateLable = styled.div`
font-size: 8px;
`;

export interface ITag {
	id: string;
	text: string;
}

const KeyCodes = {
	comma: 188,
	enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];
export class IdeaSummaryComponent extends React.Component<IIdeaProps, IIdeaState> {
	private _ideaSummaryFactory: IdeaSummaryFactory;

	constructor(props: IIdeaProps) {
		super(props);

		this._ideaSummaryFactory = new IdeaSummaryFactory(this.props.readOnlyFontColor);

		this.state = {
			idea: props.idea,
			currentTag: ``,
			updateSent: ``,
			tags: this.props.idea.tags.split(',').map(a => { return { id: a, text: a }; }),
			suggestions: props.data.tagsSuggestion ? props.data.tagsSuggestion.tags : null
		};

		this.ideaTitleOnChange = this.ideaTitleOnChange.bind(this);
		this.textareaOnChange = this.textareaOnChange.bind(this);
		this.dropdownOnChange = this.dropdownOnChange.bind(this);
		this.sendButtonClick = this.sendButtonClick.bind(this);
		this.currentTagOnChange = this.currentTagOnChange.bind(this);
		this.locationOnChange = this.locationOnChange.bind(this);

		this.handleDelete = this.handleDelete.bind(this);
		this.handleAddition = this.handleAddition.bind(this);
		this.handleDrag = this.handleDrag.bind(this);

	}

	// >>>>> tags
	handleDelete(i: number) {
		const { tags } = this.state;
		this.setState({
			tags: tags.filter((tag, index) => index !== i),
		});
		this.setState(state => ({ ...state, currentTag: this.state.tags.map(a => a.text).join(',') }));
	}

	handleAddition(tag: ITag) {
		//console.log(tag);
		// const newSuggestions = this.state.suggestions.filter((suggestedTag: ITag) => {
		// 	if (suggestedTag !== tag) {
		// 		return true;
		// 	}
		// 	return false;
		// });
		this.setState({
			...this.state,
			tags: [...this.state.tags, tag],
			currentTag: this.state.tags.map(a => a.text).join(','),
		});

		//this.updateTagsInIdeaState();

	}

	handleDrag(tag: ITag, currPos: number, newPos: number) {
		const tags = [...this.state.tags];
		const newTags = tags.slice();

		newTags.splice(currPos, 1);
		newTags.splice(newPos, 0, tag);

		this.setState({ tags: newTags });
		this.setState(state => ({ ...state, currentTag: this.state.tags.map(a => a.text).join(',') }));

	}
	// <<<<< tags
	toggleEdit = (stateName: string) => {
		this.setState({
			...this.state,
			[`isEditable${stateName}`]: !this.state[`isEditable${stateName}`]
		});
	}

	ideaTitleOnChange = (e: React.ChangeEvent<any>) => {
		let newState = Object.assign({}, this.state);
		newState.idea.title = e.target.value;
		this.setState(newState);
	}

	textareaOnChange = (id: string, e: React.ChangeEvent<HTMLTextAreaElement>) => {
		let newState = Object.assign({}, this.state);

		if (id === 'body') {
			newState.idea.body = (e.target.value === '') ? '233 ' : e.target.value;
		} else {
			newState.idea.extQuestions[id].answer = (e.target.value === '') ? ' 322' : e.target.value;
		}

		this.setState(newState);
	}

	dropdownOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		let newState = Object.assign({}, this.state);
		newState.idea.category = e.target.value;
		this.setState(newState);
	}
	locationOnChange = (lat: number, lon: number) => {
		let newState = Object.assign({}, this.state);
		newState.idea.lat = lat;
		newState.idea.lon = lon;
		this.setState(newState);
	}

	currentTagOnChange = (value: string) => {
		this.setState({
			...this.state,
			currentTag: value
		});
	}

	sendButtonClick = () => {

		let ideaSend = Object.assign({}, this.state.idea);
		ideaSend.tags = this.state.tags.map(a => a.text).join(',');

		sendPostbackMessage('aktualisieren', { update: { idea: ideaSend } }, this.props.userID, false);
		this.setState({
			...this.state,
			updateSent: `aktualisiert am ${moment().format('H:m:ss')}`
		});

	}

	render() {

		const { data, className, isOwnMessage, children, isLastMessage, section } = this.props;
		const { idea, currentTag } = this.state;

		const customStyle = {
			justifyContent: isOwnMessage ? 'flex-end' : undefined
		};

		return (
			<Message isOwnMessage={isOwnMessage} css={customStyle} isLastMessage={isLastMessage} section={section}>
				<div className={className}>
					{
						this._ideaSummaryFactory.createIdeaTitle(
							'Titel: ',
							idea.title,
							this.ideaTitleOnChange, idea.readonly,
							this.toggleEdit.bind(this, `title`)

						)
					}
					{
						this._ideaSummaryFactory.createIdeaTextArea(
							'Beitragstext: ',
							idea.body,
							(e) => this.textareaOnChange('body', e),
							idea.readonly,
							'textbody',

						)
					}
					{
						idea.category &&
						this._ideaSummaryFactory.createDropdown(
							'Kategorie: ',
							idea.category,
							data.categories,
							this.dropdownOnChange,
							idea.readonly

						)
					}
					{idea.tags && this._ideaSummaryFactory.createTagsHandler(
						'Schlagworte (durch Komma getrennt): ',
						this.state.tags,
						(data.tagsSuggestion) ? data.tagsSuggestion.tags : [],
						this.handleDelete,
						this.handleAddition,
						this.handleDrag,
						delimiters,
						idea.readonly
					)
					}
					{idea.lat !== 0 && idea.lon !== 0 && idea.lat !== undefined && idea.lon !== undefined &&
						this._ideaSummaryFactory.createMapHandler(
							'Ort: ',
							idea.lat,
							idea.lon,
							this.locationOnChange,
							idea.readonly

						)
					}
					{
						idea.extQuestions.map((question: IExtQuestion, idx: number) => {
							return (
								question.answer && question.answer !== '' &&
								this._ideaSummaryFactory.createIdeaTextArea(
									question.text,
									question.answer,
									(e) => this.textareaOnChange(idx.toString(), e),
									idea.readonly,
									`quest${question.id}`,

								)
							);
						})
					}
					{
						(idea.readonly === false) &&
						<React.Fragment><Button
							type={1}
							title={'aktualisieren'}
							onClick={this.sendButtonClick}
							payload={''}
							backgroundColor={'white'}
							fontColor={'#0033a0'}
							fontSize={14}
							fontFamily={'helvetica'}
							key={123}
							idx={213}
							firstButtonMarginTop={5}
						/>
							<UpdateLable>{this.state.updateSent}</UpdateLable>
						</React.Fragment>
					} 

				</div>
				{children}
			</Message >			
		);
	}
}

const FadeIn = keyframes`
0%   {opacity:0; transform:  translate(0px,15px); }
100% {opacity:1; transform:  translate(0px,0px);}

0%   {opacity:0; -moz-transform:  translate(0px,15px);}
100% {opacity:1; -moz-transform:  translate(0px,0px);}
0%   {opacity:0; -webkit-transform:  translate(0px,15px);}
100% {opacity:1; -webkit-transform:  translate(0px,0px);}
0%   {opacity:0; -o-transform:  translate(0px,15px)}
100% {opacity:1; -o-transform:  translate(0px,0px);}
0%   {opacity:0; -ms-transform:  translate(0px,15px);}
100% {opacity:1; -ms-transform:  translate(0px,0px);}
`;

const IdeaSummaryStyled = styled(IdeaSummaryComponent)`
	&&&{
		width: 99%;
		max-width: 99%;
		font-size: 16px;
		line-height: normal;
		background-color: ${props => props.backgroundColor};
		color: ${props => props.fontColor};
		border-radius: 20px;
		border-bottom-right-radius: ${props => props.isOwnMessage === true
		&& (props.section === Section.end || props.section === Section.single) ? '0px' : '20px'};
		border-bottom-left-radius: ${ props => props.isOwnMessage === false
		&& (props.section === Section.end || props.section === Section.single) ? '0px' : '20px'};
		padding: 11px 3px 11px 3px;
		font-family: ${ props => props.fontFamily};
		align-self: ${ props => props.isOwnMessage ? 'flex-end' : 'flex-start'};
		word-wrap: break-word;
		display: flex;
    	flex-flow: column;


		animation: ${FadeIn} ease 1s;
		animation-iteration-count: 1;
		transform-origin: 10% 10%;
		animation-fill-mode:backwards; /*when the spec is finished*/
		-webkit-animation: ${FadeIn} ease 1s;
		-webkit-animation-iteration-count: 1;
		-webkit-transform-origin: 10% 10%;
		-webkit-animation-fill-mode:backwards; /*Chrome 16+, Safari 4+*/ 
		-moz-animation: ${FadeIn} ease 1s;
		-moz-animation-iteration-count: 1;
		-moz-transform-origin: 10% 10%;
		-moz-animation-fill-mode:backwards; /*FF 5+*/
		-o-animation: ${FadeIn} ease 1s;
		-o-animation-iteration-count: 1;
		-o-transform-origin: 10% 10%;
		-o-animation-fill-mode:backwards; /*Not implemented yet*/
		-ms-animation: ${FadeIn} ease 1s;
		-ms-animation-iteration-count: 1;
		-ms-transform-origin: 10% 10%;
		-ms-animation-fill-mode:backwards; /*IE 10+*/
	
		opacity:0;
		opacity: 1\9;
		.ReactTags__tags {color: ${props => props.backgroundColor}}
	
	}
`;

export const IdeaSummary = IdeaSummaryStyled;