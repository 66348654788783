/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:35:08
 * @modify date 2019-01-28 01:35:08
 * @desc [description]
 */
export type MessageID = string;
export type Timestamp = number;

export enum WSMessageEvent {
	UserNotFound = 'user_not_found',
	UserFound = 'user_found',
	UserCreated = 'user_created',
	PUIDInvalid = 'puid_invalid',
	PageIDNotFound = 'pageid_not_found',
	PageIDMissing = 'pageid_missing',
	VerfiyTokenMissing = 'verifytoken_missing',
	ConnectionRejected = 'connection_rejected',
	NewMessage = 'new_message',
	NewAction = 'new_action'
}

export interface IWebsocketMessageEvent<PayloadTyp = string> {
	statusCode: number;
	connected: boolean;
	type: WSMessageEvent;
	payload?: PayloadTyp;
}

export interface ITag {
	id: string;
	text: string;
}

export interface IDetectedTag {
	tag: string;
	match: string[];
}
export interface ISuggestedTags {
	tags: ITag[];
	detectedTags: IDetectedTag[];
}
