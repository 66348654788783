/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:32:34
 * @modify date 2019-01-28 01:32:34
 * @desc [description]
 */
import * as constants from './toolbar.constants';

// Add textbox change mode to text
export interface IToggleTextboxModeText {
	type: constants.TEXTBOX_MODE_TEXT;
}

export const toggleTextboxModeText = (): IToggleTextboxModeText => ({
	type: constants.TEXTBOX_MODE_TEXT
});

// Add textbox change mode to tags
export interface IToggleTextboxModeTags {
	type: constants.TEXTBOX_MODE_TAGS;
}

export const toggleTextboxModeTags = (): IToggleTextboxModeTags => ({
	type: constants.TEXTBOX_MODE_TAGS
});

// Export all possible actions as a combined type
export type ToolbarAction = IToggleTextboxModeTags | IToggleTextboxModeText;