/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:32:44
 * @modify date 2019-01-28 01:32:44
 * @desc [description]
 */
import { ToolbarAction } from './toolbar.actions';
import { IToolbarState, TextboxMode } from './toolbar.schema';
import * as constants from './toolbar.constants';

export const toolbarDefaultState: IToolbarState = {
	textbox: { show: true, mode: TextboxMode.Text }
};

export const toolbarReducer = (state: IToolbarState = toolbarDefaultState, action: ToolbarAction) => {
	switch (action.type) {
		case constants.TEXTBOX_MODE_TEXT:
			return {
				...state,
				textbox: { ...state.textbox, mode: TextboxMode.Text }
			};
		case constants.TEXTBOX_MODE_TAGS:
			return {
				...state,
				textbox: { ...state.textbox, mode: TextboxMode.Tags }
			};

		default: return state;
	}
};