/**
 * @author addBots
 * @email kontakt@addbots.de
 * @create date 2019-01-28 01:37:30
 * @modify date 2019-01-28 01:37:30
 * @desc [description]
 */
import 'react';
import styled from 'styled-components';

const ChatViewHeaderCloseButtonStyled = styled.img`
	height: 16px;
	width: 16px
	margin: auto;
	margin-right: 8px;


`;

export const ChatViewHeaderCloseButton = ChatViewHeaderCloseButtonStyled;
