/**
 * @author Moataz Ibrahim
 * @email moataz90@gmail.com
 * @create date 2019-01-28 01:20:05
 * @modify date 2019-01-28 01:20:05
 * @desc [description]
 */
import { IdeaSummaryAction } from './idea.actions';
import { IIdeaSummaryState } from './idea.schema';
import * as constants from './idea.constants';

const ideaSummaryDefaultState: IIdeaSummaryState = {
	categories: null,
	tagsSuggestion: null
};

export const IdeaSummaryReducer = (state: IIdeaSummaryState = ideaSummaryDefaultState, action: IdeaSummaryAction): IIdeaSummaryState => {
	switch (action.type) {
		case constants.CATEGORY_LIST_SET:
			return { ...state, categories: action.payload };

		case constants.TAGS_SUGGESTION_LIST_SET:
			return { ...state, tagsSuggestion: action.payload };

		default:
			return state;
	}
};