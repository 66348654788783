/**
 * @author Moataz Ibrahim
 * @email moataz90@gmail.com
 * @create date 2019-01-28 01:20:05
 * @modify date 2019-01-28 01:20:05
 * @desc [description]
 */
import * as React from 'react';
import { Textbox } from './Textbox';
import { SendButton } from './SendButton';
import { sendTextMessage } from '../../../../socket-handler/socket-handler';
import styled, { keyframes } from 'styled-components';
import { connect, Dispatch } from 'react-redux';
import { IStoreSchema } from '../../../../redux-store/store-schema';
import { ITextbox, TextboxMode } from '../../../../redux-store/toolbar/toolbar.schema';
import { IToolbarConfig } from '../../../../config/config-schema';
// import { WithContext as ReactTags } from 'react-tag-input';
import { ISuggestedTags } from './.././../../../types/common-types';
import { timer } from 'rxjs';

export interface IChatToolBarProps  /*  extends IPropsChildable  */ {
	className?: string;
	textbox: ITextbox;
	dispatch: Dispatch<any>;
	// theme: IThemeSchema;
	fontFamily: string;
	userID: string;
	toolbarConfig: IToolbarConfig;
	tagsSuggestion: ISuggestedTags;
}

export interface IChatToolBarState {
	TextboxValue: string;
	tags: ITag[];
}
export interface ITag {
	id: string;
	text: string;
}

export interface ITagsState {
	tags: ITag[];
	suggestions: ITag[];
}

const KeyCodes = {
	comma: 188,
	enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
const IDLE_TIME = 60;

const TagsHolder = styled.div`
	width: 100%;
	position: relative;
`;
class ChatToolBarComponent extends React.Component<IChatToolBarProps, IChatToolBarState> {
	timeLeft: number = IDLE_TIME;
	interval: any;

	constructor(props: IChatToolBarProps) {
		super(props);

		// props.dispatch(getPersistentMenu());

		this.state = {
			TextboxValue: '',
			tags: this.formatInitialTags(props.tagsSuggestion)
		};

		this.TextboxOnChange = this.TextboxOnChange.bind(this);
		this.SendButtonOnClick = this.SendButtonOnClick.bind(this);

		this.handleDelete = this.handleDelete.bind(this);
		this.handleAddition = this.handleAddition.bind(this);
		this.handleDrag = this.handleDrag.bind(this);
		//this.startTimer();
	}

	resetTimer() {
		this.timeLeft = IDLE_TIME;
		clearInterval(this.interval);
		//this.startTimer();
	}

	startTimer() {
		this.interval = setInterval(() => {
			if (this.timeLeft > 0) {
				this.timeLeft--;
			} else {
				clearInterval(this.interval);
				this.alertRefreshPage();
			}
		}, 1000);
	}

	alertRefreshPage() {
		if (confirm('Möchten Sie einen neuen Beitrag eingeben?')) {
			this.refreshPageOnIdle();
		} else {
			this.resetTimer();
		}
	}
	handleDelete(i: number) {
		const { tags } = this.state;
		this.setState({
			tags: tags.filter((tag, index) => index !== i),
		});
	}

	handleAddition(tag: ITag) {
		this.setState(state => ({ tags: [...state.tags, tag] }));
	}

	handleDrag(tag: ITag, currPos: number, newPos: number) {
		const tags = [...this.state.tags];
		const newTags = tags.slice();

		newTags.splice(currPos, 1);
		newTags.splice(newPos, 0, tag);

		// re-render
		this.setState({ tags: newTags });
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IChatToolBarProps) {
		const { tagsSuggestion } = this.props;

		if (nextProps.tagsSuggestion && nextProps.tagsSuggestion !== tagsSuggestion) {
			this.setState({ tags: this.formatInitialTags(nextProps.tagsSuggestion) });
		}
	}
	public formatInitialTags(suggestedTags: ISuggestedTags) {

		let initTagsFormatted: ITag[] = [];
		if (suggestedTags) {
			suggestedTags.detectedTags.map((tag) => {

				initTagsFormatted.push({ id: tag.tag, text: tag.tag });
			});
		}

		return initTagsFormatted;
	}

	public refreshPageOnIdle(): void {
		window.location.reload();
	}

	public TextboxOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ TextboxValue: event.target.value });
		this.resetTimer();
	}

	public SendButtonOnClick = () => {
		if (this.state.TextboxValue.trim() !== '') {
			sendTextMessage(this.state.TextboxValue, this.props.userID, true);
			this.setState({ TextboxValue: '' });

		} else if (this.state.tags.length !== 0) {
			sendTextMessage(this.state.tags.map(a => a.text).join(','), this.props.userID, true);
			this.setState({ tags: [] });

		}
	}
	public SendButtonOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {

		if (e.key === 'Enter') {
			this.SendButtonOnClick();
			// let withLineBreak = this.state.TextboxValue.concat('{`/n`}').concat('test');
			// console.log(withLineBreak);
			// {
			// 	this.state.TextboxValue.split('\n').map((item, key) => {
			// 		return <HTMLInputElement>{item}<br /></HTMLInputElement>
			// 	})
			// }
			// this.setState({ TextboxValue: withLineBreak });
			// if (this.state.TextboxValue.trim() !== '') {
			// 	sendTextMessage(this.state.TextboxValue, this.props.userID, true);
			// 	this.setState({ TextboxValue: '' });
			// }
		}
	}

	public render() {
		const { className, tagsSuggestion } = this.props;
		const { tags } = this.state;
		return (

			(this.props.toolbarConfig.enablePersistentMenu === true || this.props.toolbarConfig.enableTextInput === true) &&
			<React.Fragment>

				<div className={className} >
					{(this.props.toolbarConfig.enableTextInput === true) &&
						<React.Fragment>
							{this.props.textbox.mode === TextboxMode.Text &&
								<Textbox
									value={this.state.TextboxValue}
									onChange={this.TextboxOnChange}
									onKeyUp={this.SendButtonOnKeyPress}
									placeholder="Nachricht schreiben …"
								/>}
							{this.props.textbox.mode === TextboxMode.Tags &&
								<TagsHolder>
									{/* <ReactTags
										inline={false}
										tags={tags}
										suggestions={tagsSuggestion.tags}
										handleDelete={this.handleDelete}
										handleAddition={this.handleAddition}
										handleDrag={this.handleDrag}
										delimiters={delimiters}
										placeholder="Schlagwort hinzufügen"
									/> */}
								</TagsHolder>
							}
							<SendButton onClick={this.SendButtonOnClick} />

						</React.Fragment>

					}
				</div>
			</React.Fragment>

		);
	}
}

const ChatToolBarComponentStyled = styled(ChatToolBarComponent)`
&&&{
	display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    /* border: 1px solid #EBEBEB; */
    /* border-bottom-left-radius: 7px; */
    border-bottom-right-radius: 7px;
    padding: 6px 10px 20px 10px;
    position: absolute;
    top: calc(100% - 51px);
    width: 100%;
    z-index: 1000;
    box-shadow: 0px -4px 20px 0px #ebebeb;
    font-family: ${props => props.fontFamily};
	font-size: 16px;
	color: #006E96;
	box-sizing: border-box;
}
`;

const mapStateToProps = (storeState: IStoreSchema) => {
	return {
		textbox: storeState.toolbar.textbox,
		tagsSuggestion: storeState.ideaSummary.tagsSuggestion,
		userID: storeState.user.sessionID,
	};
};

export const ChatToolBar = connect(mapStateToProps)(ChatToolBarComponentStyled);
