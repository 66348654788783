/**
 * @author Moataz Ibrahim
 * @email moataz90@gmail.com
 * @create date 2019-01-28 01:20:05
 * @modify date 2019-01-28 01:20:05
 * @desc [description]
 */
import * as React from 'react';
/* tslint:disable:no-unused-variable */
import styled, { StyledComponentClass, keyframes } from 'styled-components';
import { IPropsStyledComponents } from '../../../../types/common-proptypes';
import { ICategory, ISubCategory } from './../../../../../redux-store/idea-summary/idea.schema';
// import * as TagsInput from 'react-tagsinput';
// import { RenderInputProps, InputProps, ReactTagsInputProps, prototype } from 'react-tagsinput';
// import { GmapWrapper } from '../../map/GmapWrapper';
import * as Autosuggest from 'react-autosuggest';

// import { WithContext as ReactTags } from 'react-tag-input';
// import './TagsInput.css';
// import 'react-tagsinput/react-tagsinput.css';
import { ITag } from './IdeaSummary';

type buttonOnClick = () => void;
type textboxOnChange = (e: React.ChangeEvent<HTMLInputElement>) => void;
type textareaOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
type dropdownOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => void;
type locationOnChange = (lat: number, lon: number) => void;

// interface IAautosuggestRenderInput {
// 	addTag: () => void;
// 	props: ReactTagsInputProps;
// }

const InputTitle = styled.input`
	outline: none;
	border: none;
    border-radius: 8px;
    padding: 10px;
	margin: 5px 10px 0px 10px
	box-shadow: 0px 0px 20px -3px rgba(0,0,0,0.3);
`;

const TextArea = styled.textarea`
	outline: none;
	border: none;
	border-radius: 8px;
	padding: 10px;
	margin: 5px 10px 0px 10px
	box-shadow: 0px 0px 20px -3px rgba(0,0,0,0.3);
`;

const TitleContainer = styled.div`
    display: flex;
	flex-flow: column;
	margin: 5px 10px 0px 10px
`;
const TextAreaContainer = styled.div`
	display: flex;
	flex-flow: column;
    margin: 5px 10px 0px 10px
`;
const DropdownContainer = styled.div`
	display: flex;
	flex-flow: column;
	margin: 5px 10px 0px 10px

`;
const TagsContainer = styled.div`
	display: flex;
	flex-flow: column;
	margin: 5px 10px 0px 10px
`;

const MapContainer = styled.div`
	display: flex;
	flex-flow: column;
	margin: 5px 10px 0px 10px
`;
const Button = styled.button`
	background: ghostwhite;
	cursor: pointer;
	border-radius: 8px;
	border: none;
	box-shadow: 0px 0px 20px -3px rgba(0,0,0,0.3);
	&:hover {
		background: whitesmoke;
	}
`;

const Label = styled.label`
	margin-bottom: 5px;
`;
const LabelAnswers = styled.label`
	margin-bottom: 10px;
	color: #5a5a57;
`;

export class IdeaSummaryFactory {

	private _readOnlyFontColor: string = null;

	constructor(readOnlyFontColor?: string) {

		this._readOnlyFontColor = (readOnlyFontColor) ? readOnlyFontColor : 'white';

	}

	public createIdeaTitle = (
		title: string,
		value: string,
		textOnChange: textboxOnChange,
		readonly: boolean,
		key?: number | string,

	): JSX.Element => {
		return (
			<TitleContainer>
				<Label>
					{title}
				</Label>
				{
					readonly ?
						<LabelAnswers style={{ color: this._readOnlyFontColor }}>{value}</LabelAnswers> :
						<InputTitle
							value={value}
							placeholder={``}
							onChange={textOnChange}

						/>
				}
			</TitleContainer>
		);
	}

	public createIdeaTextArea = (
		title: string,
		value: string,
		textOnChange: textareaOnChange,
		readonly: boolean,
		key?: number | string
	): JSX.Element => {
		return (
			<TextAreaContainer>
				<Label>
					{title}
				</Label>
				{readonly ?
					<LabelAnswers style={{ color: this._readOnlyFontColor }}>{value}</LabelAnswers> :
					<TextArea
						value={value}
						placeholder={``}
						onChange={textOnChange}
					/>
				}

			</TextAreaContainer>
		);
	}

	public createDropdown = (
		title: string,
		selectedCategory: string,
		categories: ISubCategory[],
		selectedItemOnChange: dropdownOnChange,
		readonly: boolean,
		key?: number | string
	): JSX.Element => {

		let readOnlyCategory = categories.find((obj: any) => { return obj.subCatId.toString() === selectedCategory; });

		return (
			<DropdownContainer>
				<Label>
					{title}
				</Label>
				{
					readonly ?
						<LabelAnswers style={{ color: this._readOnlyFontColor }}>
							{
								categories.find((obj: any) => { return obj.subCatId.toString() === selectedCategory; }).name}

						</LabelAnswers> :
						<select onChange={selectedItemOnChange} style={{ boxShadow: ` 0px 0px 20px -3px rgba(0,0,0,0.3)`, borderRadius: `5px` }}>
							{
								categories.map((subcategory: ISubCategory, idx: number) => {
									return (
										<option
											key={`subcategory-${subcategory.subCatId}`}
											id={`${subcategory.subCatId}`}
											selected={selectedCategory.toString() === subcategory.name.toString()}
										>
											{subcategory.name}
										</option>
									);
								})
							}
						</select>
				}
			</DropdownContainer>
		);
	}

	public createTagsHandler = (
		title: string,
		tags: ITag[],
		suggestions: any,
		handleDelete: any,
		handleAddition: any,
		handleDrag: any,
		delimiters: any,
		readonly: boolean,
		key?: number | string
	): JSX.Element => {

		return (
			<TagsContainer>
				<Label>
					{title}
				</Label>
				{

					// readonly ?
					// 	<LabelAnswers style={{ color: this._readOnlyFontColor }}>{tags.map(e => e.text).join(',')}</LabelAnswers> :
					// 	<ReactTags

					// 		tags={tags}
					// 		placeholder="Schlagwort hinzufügen"
					// 		suggestions={suggestions}
					// 		handleDelete={handleDelete}
					// 		handleAddition={handleAddition}
					// 		handleDrag={handleDrag}
					// 		delimiters={delimiters}
					// 		inline={true}
					// 		classNames={{ selected: 'Tags_selected_summary ReactTags__selected', tagInput: 'tagInput_summary ReactTags__tagInput' }}
					// 	/>
				}
			</TagsContainer>
		);
	}

	public createMapHandler = (
		title: string,
		lat: number,
		lon: number,
		onChange: locationOnChange,
		readonly: boolean,
		key?: number | string
	): JSX.Element => {
		return (
			<MapContainer>
				<Label >
					{title}
				</Label>
				{/* {
					<GmapWrapper locationOnChange={onChange} lat={lat} lon={lon} readonly={readonly} />
					} */}
			</MapContainer>
		);
	}

	public createSendButton = (
		onSendClick: buttonOnClick,
		key?: string | number
	): JSX.Element => {
		return (
			<button onClick={onSendClick}>
				Abschicken
			</button>
		);
	}

}